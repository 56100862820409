// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/Users/bianchimro/projects/inmagik-home/src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("/Users/bianchimro/projects/inmagik-home/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/Users/bianchimro/projects/inmagik-home/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("/Users/bianchimro/projects/inmagik-home/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("/Users/bianchimro/projects/inmagik-home/src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-copyright-js": () => import("/Users/bianchimro/projects/inmagik-home/src/pages/copyright.js" /* webpackChunkName: "component---src-pages-copyright-js" */),
  "component---src-pages-index-js": () => import("/Users/bianchimro/projects/inmagik-home/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-opensource-js": () => import("/Users/bianchimro/projects/inmagik-home/src/pages/opensource.js" /* webpackChunkName: "component---src-pages-opensource-js" */),
  "component---src-pages-portfolio-js": () => import("/Users/bianchimro/projects/inmagik-home/src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-policy-js": () => import("/Users/bianchimro/projects/inmagik-home/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("/Users/bianchimro/projects/inmagik-home/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-tech-js": () => import("/Users/bianchimro/projects/inmagik-home/src/pages/tech.js" /* webpackChunkName: "component---src-pages-tech-js" */),
  "component---src-pages-terms-of-use-js": () => import("/Users/bianchimro/projects/inmagik-home/src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-work-with-us-js": () => import("/Users/bianchimro/projects/inmagik-home/src/pages/work-with-us.js" /* webpackChunkName: "component---src-pages-work-with-us-js" */)
}

