module.exports = [{
      plugin: require('/Users/bianchimro/projects/inmagik-home/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/bianchimro/projects/inmagik-home/src/locale","languages":["en","it"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('/Users/bianchimro/projects/inmagik-home/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/INMAGIK_COLOR.png"},
    },{
      plugin: require('/Users/bianchimro/projects/inmagik-home/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":900,"linkImagesToOriginal":false},
    },{
      plugin: require('/Users/bianchimro/projects/inmagik-home/node_modules/gatsby-remark-images-medium-zoom/gatsby-browser.js'),
      options: {"plugins":[],"margin":15,"zIndex":1021},
    },{
      plugin: require('/Users/bianchimro/projects/inmagik-home/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/bianchimro/projects/inmagik-home/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-44618885-1","head":false,"anonymize":true},
    },{
      plugin: require('/Users/bianchimro/projects/inmagik-home/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
